<template>
  <v-container class="not-extended">
    <v-data-table v-bind="{ ...bind.table, ...tableBind }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn v-bind="bind.refresh" v-on="on.refresh">
            <v-icon>{{ icons.refresh }}</v-icon>
          </v-btn>
          <v-btn v-bind="bind.new" v-on="on.new">
            {{ labels.new }}
          </v-btn>
        </v-toolbar>
      </template>

      <template #item.id_ramo="{ item }">
        {{
          branches?.find((e) => e.ramo == item.id_ramo)?.ramo_descripcion ??
          item.id_ramo
        }}
      </template>

      <template #item.actions="{ item }">
        <v-tooltip left color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              x-small
              text
              fab
              @click="editItem(item)"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon small> {{ icons.edit }} </v-icon>
            </v-btn>
          </template>
          <span>Editar la información de {{ item.descripcion }}</span>
        </v-tooltip>
        <v-tooltip left color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              x-small
              text
              fab
              @click="deleteItem(item)"
              v-on="on"
              v-bind="attrs"
              :disabled="item.eliminado"
            >
              <v-icon small> {{ icons.delete }} </v-icon>
            </v-btn>
          </template>
          <span>Eliminar {{ item.descripcion }} del listado</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- MODAL CREAR Y EDITAR -->
    <v-dialog v-model="dialog" @click:outside="close()" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            !!selected ? "Editar Materia" : "Crear Materia"
          }}</span>

          <v-spacer></v-spacer>
          <v-btn color="primary" x-small fab text @click="close()">
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-autocomplete
                :items="branches"
                v-model="form.id_ramo"
                :loading="loadingBranches"
                item-text="ramo_descripcion"
                item-value="ramo"
                label="Seleccione un Ramo"
                :rules="[(v) => !!v || 'Debe seleccionar un ramo']"
              ></v-autocomplete>
              <v-row no-gutters>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="form.descripcion"
                    class="mr-1"
                    label="Descripción"
                    placeholder="Descripción de la materia asegurada"
                    :rules="[(v) => !!v || 'Escriba una descripción']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.codigo"
                    class="ml-1"
                    label="Código"
                    :rules="[(v) => !!v || 'Escriba un código']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="handlerSave()"
            :loading="loadingForm"
          >
            {{ selected ? "Actualizar" : "Guardar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-modal ref="confirm" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PaginationMixin from "@/Mixins/PaginationMixin.js";
import PageMixin from "@/Mixins/SetPageMixin.js";
export default {
  name: "CrudMateriaAsegurada",
  mixins: [PaginationMixin, PageMixin],
  data: () => ({
    branches: [],
    loadingBranches: false,
    form: {
      descripcion: "",
      id_ramo: "",
      codigo: "",
    },
  }),
  computed: {
    ...mapGetters("MateriaAsegurada", ["getItems"]),

    items() {
      return this.getItems;
    },

    headers() {
      return [
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "descripcion",
        },
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo",
        },
        {
          text: "Ramo",
          align: "start",
          sortable: true,
          value: "id_ramo",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ];
    },
  },
  methods: {
    ...mapActions("NewProduct", ["getRamos"]),
    ...mapActions("MateriaAsegurada", ["GET", "POST", "PUT", "DELETE"]),

    async setup() {
      this.handlerGetItems();
      this.handlerGetRamos();
    },
    handlerGetItems() {
      this.loading = true;
      this.GET(this.pagination)
        .then((response) => {
          this.setPaginationFromResponse(response?.pagination);
        })
        .catch((error) => {
          this.snackbar(
            error?.info ||
              "Ha ocurrido un error al internar recuperar las materias."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlerGetRamos() {
      this.loadingBranches = true;
      this.getRamos()
        .then((r) => (this.branches = r))
        .finally(() => {
          this.loadingBranches = false;
        });
    },
    handlerSave() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.selected) {
          this.update();
        } else {
          this.save();
        }
      }
    },
    save() {
      this.loadingForm = true;
      this.POST(this.form)
        .then((response) => {
          const message = response.info;
          this.snackbar(message, "success");
          this.handlerGetItems();
          this.close();
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => (this.loadingForm = false));
    },
    update() {
      this.loadingForm = true;
      const payload = {
        id: this.selected._id,
        formData: this.form,
      };
      this.PUT(payload)
        .then((response) => {
          const message = response.info;
          this.snackbar(message, "success");
          this.handlerGetItems();
          this.close();
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => (this.loadingForm = false));
    },
    deleteItem(item) {
      this.loadingForm = true;
      this.$refs.confirm
        .show({
          title: "Eliminar materia asegurada",
          description: "Esta a un paso de eliminar la materia asegurada.",
        })
        .then((answer) => {
          if (answer) {
            this.DELETE(item._id)
              .then((response) => {
                const message = response.info;
                this.snackbar(message, "success");
                this.handlerGetItems();
              })
              .catch((error) => {
                this.snackbar(error.info);
              })
              .finally(() => (this.loadingForm = false));
          }
        });
    },
    close() {
      this.dialog = false;
      this.form.descripcion = "";
      this.form.id_ramo = "";
      this.form.codigo = "";
      this.selected = null;
    },
    editItem(item) {
      this.selected = item;
      this.form.descripcion = item.descripcion;
      this.form.id_ramo = item.id_ramo;
      this.form.codigo = item.codigo || "";
      this.dialog = true;
    },
  },
};
</script>
